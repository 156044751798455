






























import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { CapaRegraImposto } from '@/models/fiscal/RegraDeImposto'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {
	FindRegraDeImpostoUseCase,
	DeleteRegraDeImpostoUseCase, FindOrigemUseCase, FindIPIUseCase,
} from '@/usecases'
import { RegraDeImpostoModule } from '@/store/vuex/fiscal/RegraDeImpostoStore'
import { DataOptions } from 'vuetify'
import Axios, { CancelTokenSource } from 'axios'
import DialogoDeCapaDeRegraFiscal from '@/views/application/configuracoes/fiscal/DialogoDeCapaDeRegraFiscal.vue'

@Component({
	components: {
		DataTableDeCrud,
		DialogoDeCapaDeRegraFiscal,
	},
})
export default class TelaDeImpostos extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeCapaDeRegraFiscal
	headers = [
		{ text: 'Descrição', value: 'descricao' },
		{ text: 'Origem', value: 'displayOrigem' },
	]

	carregando = false

	busca = ''

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	totalRegistros = -1
	cancelToken: CancelTokenSource | null = null

	findRegraDeImpostoUseCase = new FindRegraDeImpostoUseCase()
	deleteRegraDeImpostoUseCase = new DeleteRegraDeImpostoUseCase()

	created() {
		this.carregar()
	}

	async carregar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = Axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const pageRegras = await this.findRegraDeImpostoUseCase.find({
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				descricao: (this.busca ? this.busca : null),
			}, axiosConfig)

			RegraDeImpostoModule.setRegras(pageRegras.content)
			this.totalRegistros = pageRegras.totalElements
		} catch (error: any) {
			if (error.message)
				AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async redireciona(regra: CapaRegraImposto) {
		await this.$router.push({
			name: 'Edição de Regra de Imposto',
			params: { id: regra.id || '' },
		})
	}

	async mostrarDialogoDeEdicao() {
		const novaRegra = await criarRegraDeImposto()
		this.dialogoDeEdicao.mostrar(novaRegra)
	}

	get itensFormatados(): DisplayCategoriasDeImposto[] {
		return this.itens.map(item => ({
			...item,
			displayOrigem: item.origem
				? `${item.origem.codigo} - ${item.origem.descricao}`
				: 'SEM ORIGEM',
		}))
	}

	get itens() {
		return RegraDeImpostoModule.regras
	}

	async removerRegra(indice: number) {
		const regra = this.itens[indice]
		if (!regra.id) return
		try {
			this.carregando = true
			await this.deleteRegraDeImpostoUseCase.delete(regra.id)
			RegraDeImpostoModule.removerRegra(regra)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.carregar

	@Watch('busca', { deep: true })
	onChangeBusca = this.carregar
}

export interface DisplayCategoriasDeImposto extends CapaRegraImposto {
	displayOrigem: string
}

async function criarRegraDeImposto(): Promise<CapaRegraImposto> {
	const findOrigemUsecase = new FindOrigemUseCase()
	const findIPIUseCase = new FindIPIUseCase()
	const [origemDefault, cstIPIDefault] = await Promise.all([
		(await findOrigemUsecase.find({ codigo: '0' })).content[0],
		(await findIPIUseCase.find({ codigo: '51' })).content[0],
	])
	return {
		descricao: '',
		origem: {
			...origemDefault,
		},
		ipi: {
			tipoDeImposto: 'IPI',
			aliquota: 0,
			csts: [
				{
					id: '',
					operacao: 'SAIDA',
					cst: {
						...cstIPIDefault,
						tipo: 'IPI',
					},
				},
				{
					id: '',
					operacao: 'DEVOLUCAO',
					cst: {
						...cstIPIDefault,
						tipo: 'IPI',
					},
				},
			],
		},
		regimeFiscal: 'Simples Nacional',
	}
}
